p, a {
    color: #fff;
}

.container {
    max-width: 1000px;
    background: #000;
    margin: 0 auto;
    padding: 5px;
}


.header {
    border-radius: 5px;
    background-color: #333;
    display: flex;
    align-items: center;
}

.header > p {
    /* font-family: 'Newsreader', serif; */
    letter-spacing: 1.2px;
    font-size: 10px;
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
}

.header > img {
    width: 60px;
}

.appNotif {
    margin-top: 10px;
}

.appNotif > p {
    background-color: #333;
    padding: 5px;
    border-radius: 5px;
    font-family: 'Newsreader', serif;
    letter-spacing: 1.2px;
    font-weight: 500;
}

.appNotif > p > a {
   color: red;
   font-weight: bold;
   font-family: 'Newsreader', serif;
   text-transform: uppercase;
   text-decoration: none;
}

.follow {
    background: red;
    padding: 5px;
    border-radius: 5px;
    font-family: 'Newsreader', serif;
    letter-spacing: 1.2px;
    font-weight: 500;
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
}

hr {
    background: #333;
    border: none;
    height: 1px;
}



.showBtn {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    height: 70px;
    align-items: center;
    margin-top: 10px;
}

.showBtn > a {
    flex: 1 100%;
    background: #333;
    margin: 5px;
    height: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
}

.showBtn > a > img {
    /* width: 100%; */
    max-height: 70px;
}

.createAccont {
    color: #fff;
    font-weight: bold;
    text-transform: capitalize;
    font-family: 'Newsreader', serif;
    text-decoration: none;
}

.appDes {
    margin-top: 10px;
    background: #333;
    padding: 5px;
    border-radius: 5px;
    text-align: center;
}

/* .showBtn {
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    overflow: hidden;
    align-items: center;
    padding: 5px;
}

.showBtn > a {
    width: 100%;
    margin: 5px;
    background: #333;
    overflow: hidden;
    border-radius: 5px;
    max-height: 80px;
    display: flex;
    justify-content: center;
    padding: 5px 0;
}

.showBtn > a > img {
    height: 65px;
    width: 168px;
}

.createAccont {
    background-color: #000;
    color: #fff;
} */

.showcase {
    display: flex;
    flex-direction: row;
    overflow: scroll;
    overflow-y: hidden;
    background: #333;
    margin-top: 10px;
    border-radius: 5px;
    padding: 10px 0;
}

.showcase > img {
    width: calc(1000px / 5 - 5px);
    margin: 5px;
}